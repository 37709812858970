<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">ARIMA分析结果</h1>
            
            <div class="result-area" id="varsProduce">
                <divider-title name="变量解释" />
                <div>
                    <div v-for="(item,key) in varsProduce" :key="key">
                        {{item.key}}: {{item.value}}
                    </div>
                </div>
            </div>
            <div class="result-area" v-if="modelDesc" id="modelDesc">
                <divider-title name="模型解释" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:50px"></th>
                                                    <th style="width:150px">方法</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>{{varsProduce[0].key}}</td>
                                                    <td>{{modelDesc.modelType}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="modelStats" id="modelStats">
                <divider-title name="模型统计量表" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th rowspan="2" style="width:50px">模型</th>
                                                    <th rowspan="2" style="width:150px">
                                                        平稳的R<sup>2</sup>
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.stationaryR2.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.stationaryR2.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th rowspan="2" style="width:150px">
                                                        R<sup>2</sup>
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.r2.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.r2.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th colspan="3" style="width:300px">Ljung-BoxQ</th>
                                                    <th rowspan="2" style="width:100px">AIC
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.aic.describe}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th rowspan="2" style="width:100px">AICc
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.aicc.describe}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th rowspan="2" style="width:100px">BIC
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.bic.describe}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th rowspan="2"></th>
                                                </tr>
                                                <tr>
                                                    <th style="width:100px">统计量</th>
                                                    <th style="width:100px">df</th>
                                                    <th style="width:100px">Sig.</th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>{{modelStats.modelCode}}</td>
                                                    <td>{{toFixedNum(modelStats.stationaryR2)}}</td>
                                                    <td>{{toFixedNum(modelStats.r2)}}</td>
                                                    <td>{{toFixedNum(modelStats.ljungBoxQ.qstatistics)}}</td>
                                                    <td>{{toFixedNum(modelStats.ljungBoxQ.df)}}</td>
                                                    <td>{{toFixedNum(modelStats.ljungBoxQ.sig)}}</td>
                                                    <td>{{toFixedNum(modelStats.aic)}}</td>
                                                    <td>{{toFixedNum(modelStats.aicc)}}</td>
                                                    <td>{{toFixedNum(modelStats.bic)}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="modelParameterEstimation" id="modelParameterEstimation">
                <divider-title name="模型参数估计" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:100px"></th>
                                                    <th style="width:100px"></th>
                                                    <th style="width:100px">估计</th>
                                                    <th style="width:100px">标准差</th>
                                                    <th style="width:100px">t</th>
                                                    <th style="width:100px">Sig.</th>
                                                    <th></th>
                                                </tr>                                                
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr>
                                                    <td rowspan="6">{{modelParameterEstimation.varCode}}{{modelParameterEstimation.transformName}}</td>
                                                    <td>d</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.d)}}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>AR(p)</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.p.est[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.p.stdError[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.p.tstat[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.p.sig[0])}}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>SAR(sp)</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sp.est[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sp.stdError[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sp.tstat[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sp.sig[0])}}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>sd</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sd)}}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>MA(q)</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.q.est[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.q.stdError[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.q.tstat[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.q.sig[0])}}</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>SMA(sq)</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sq.est[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sq.stdError[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sq.tstat[0])}}</td>
                                                    <td>{{toFixedNum(modelParameterEstimation.sq.sig[0])}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" id="residualAutocorrelation">
                <divider-title name="残差自相关" />
                <a-row>
                    <a-col :span="8">                        
                        <div class="ant-table-wrapper">
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                        <div class="ant-table-content">
                                            <div class="ant-table-body">
                                                <table class="">
                                                    <thead class="ant-table-thead">
                                                        <tr>
                                                            <th rowspan="2" style="width:50px"></th>
                                                            <th colspan="2" style="width:200px">{{residualAutocorrelation.modelName}}</th>
                                                            <th rowspan="2"></th>
                                                        </tr>
                                                        <tr>
                                                            <th>ACF</th>
                                                            <th>SE</th>                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody class="ant-table-tbody">
                                                        <tr v-for="i in residualAutocorrelation.acf.length" :key="i">
                                                            <td>{{i}}</td>
                                                            <td>{{toFixedNum(residualAutocorrelation.acf[i-1])}}</td>
                                                            <td>{{toFixedNum(residualAutocorrelation.se[i-1])}}</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="16">
                        <chart :chartData="result.raBarChart" />
                    </a-col>
                </a-row>
            </div>

            <div class="result-area" id="residualPartialAutocorrelation">
                <divider-title name="残差偏自相关" />
                <a-row>
                    <a-col :span="8">
                        <div class="ant-table-wrapper">
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                        <div class="ant-table-content">
                                            <div class="ant-table-body">
                                                <table class="">
                                                    <thead class="ant-table-thead">
                                                        <tr>
                                                            <th rowspan="2" style="width:50px"></th>
                                                            <th colspan="2" style="width:200px">{{residualPartialAutocorrelation.modelName}}</th>
                                                            <th rowspan="2"></th>
                                                        </tr>
                                                        <tr>
                                                            <th>ACF</th>
                                                            <th>SE</th>                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody class="ant-table-tbody">
                                                        <tr v-for="i in residualPartialAutocorrelation.pacf.length" :key="i">
                                                            <td>{{i}}</td>
                                                            <td>{{toFixedNum(residualPartialAutocorrelation.pacf[i-1])}}</td>
                                                            <td>{{toFixedNum(residualPartialAutocorrelation.se[i-1])}}</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="16">
                        <chart :chartData="result.rpaBarChart" />
                    </a-col>
                </a-row>
            </div>

            <div class="result-area" id="tx">
                <divider-title name="图形" />
                <chart :chartData="result.lineChart" />
            </div>

        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import Chart from './components/Chart.vue';
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle, Chart},
    computed:{
        result() {
            let data = getSessionItem('analysis_arima');
            return data.analysis_arima;
        },
        parts() {
            let arr = [
                {name: '变量解释', keys: ['varsProduce']},
                {name: '模型解释', keys: ['modelDesc']},
                {name: '模型统计量表', keys: ['modelStats']},
                {name: '模型参数估计', keys: ['modelParameterEstimation']},
                {name: '残差自相关', keys: ['residualAutocorrelation']},
                {name: '残差偏自相关', keys: ['residualPartialAutocorrelation']},
                {name: '图形', keys: ['tx']}
            ]
            return arr;
        },
        varsProduce() {
            if(this.result) {
                return this.result.arimaBO.varsProduce;
            } else {
                return null;
            }
        },
        modelDesc() {
            if(this.result) {
                return this.result.arimaBO.modelDesc;
            } else {
                return null;
            }
        },
        modelStats() {
            if(this.result) {
                return this.result.arimaBO.modelStats;
            } else {
                return null;
            }
        },
        modelParameterEstimation() {
            if(this.result) {
                return this.result.arimaBO.modelParameterEstimation;
            } else {
                return null;
            }
        },
        itemInfos() {
            if(this.result) {
                return this.result.itemInfos;
            } else {
                return null;
            }
        },
        residualAutocorrelation() {
            if(this.result) {
                return this.result.arimaBO.residualAutocorrelation;
            } else {
                return null;
            }
        },
        residualPartialAutocorrelation() {
            if(this.result) {
                return this.result.arimaBO.residualPartialAutocorrelation;
            } else {
                return null;
            }
        }

        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            if(num) {
                return num.toFixed(4);
            } else {
                return '-';
            }
        }, 
    }
}
</script>